import { Helmet } from 'react-helmet';
import React from 'react';
import { Link } from 'gatsby';
import './home.css';

const CompanyLink = ({
  className,
  href,
  children,
}: {
  className: string;
  href: string;
  children: React.ReactNode;
}) => (
  <a
    className={`company ${className}`}
    href={href}
    target="_blank"
    rel="noreferrer"
  >
    {children}
  </a>
);

const Home = () => (
  <>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <main className="home">
      <section className="intro">
        <span>
          Hi, I&apos;m Daniel Hayes, a Brooklyn based Software Engineer. I have
          previously worked for
        </span>
        <span style={{ whiteSpace: 'nowrap' }}>
          {' '}
          <CompanyLink className="mcd" href="https://www.mcdpartners.com/">
            MCD Partners
          </CompanyLink>
          ,
        </span>
        <CompanyLink className="newyorker" href="https://www.newyorker.com/">
          The New Yorker
        </CompanyLink>
        ,
        <CompanyLink className="meetup" href="https://www.meetup.com/">
          Meetup
        </CompanyLink>
        <span>and I currently work at</span>{' '}
        <CompanyLink className="mux" href="https://www.mux.com/">
          Mux
        </CompanyLink>
        <span>.</span>
        <p>
          I am always looking for new projects, so please feel free to{' '}
          <CompanyLink className="mail" href="mailto:hello@danhayes.me">
            reach out
          </CompanyLink>{' '}
          if you&apos;d like to work together.{' '}
        </p>
      </section>

      <section className="footer">
        <Link className="footer-link" to="/resume/">
          Resume
        </Link>
        <a
          className="footer-link"
          href="https://github.com/daniel-hayes/"
          target="_blank"
          rel="noreferrer"
        >
          Github
        </a>
        <a
          className="footer-link"
          href="https://www.linkedin.com/in/danieladamhayes/"
          target="_blank"
          rel="noreferrer"
        >
          LinkedIn
        </a>
      </section>
    </main>
  </>
);

export default Home;
